import SectionActivationSeedingVerification from '../components/sections/activation-seeding-verification';
import SectionFooter from '../components/sections/footer';

import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import { IPage } from '../models/page.model';
import { useUser } from '../hooks/use-user';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';

interface IPanelProps {
    readonly data: {
        page: IPage;
        loginPage: Pick<IPage, 'pathname'> | null;
    };
}

const Panel: React.FC<IPanelProps> = ({ data }) => {
    const { page, loginPage } = data;
    const userState = useUser();

    const { mainSectionIndex } = getPageConfigFromSections(page.sections);

    const sections = getSectionsWithRepeatIndex(page.sections);

    useEffect(() => {
        if (!userState.isLoggedIn) {
            navigate(loginPage?.pathname || '/');
        }
    }, [loginPage, userState.isLoggedIn]);

    return (
        <MainLayout>
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        loginPage: page(type: { eq: "login" }, locale: { eq: $locale }) {
            pathname
        }
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
    }
`;

export default Panel;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'activation-seeding-verification': SectionActivationSeedingVerification,
    'footer': SectionFooter,
};